<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>签到信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="用户名称">
                <div style="width: 500px;">
                  {{ formData.realName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="手机号码">
                <div style="width: 500px;">
                  {{ formData.telephone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="网格卡点">
                <div style="width: 500px;">
                  {{ formData.gridName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="签到时间">
                <div style="width: 500px;">
                  {{ formData.signInTimeDate }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="签到地点">
                <div style="width: 500px;">
                  {{ formData.signInAddress }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div id="container" style="margin: 10px;"></div>
        </a-spin>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { gridSignInInfo } from '@/api/gridevent'
import TMap from '@/utils/initMap'
export default {
  name: 'SeeSignin',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {}
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    dataInfoApi (id) {
      const _this = this
      _this.seeInfoVisible = true
      _this.loading = true
      gridSignInInfo({ id: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.formData = res.result
          _this.loading = false
          _this.showDitu(_this.formData.latitude, _this.formData.longitude)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    },
    showDitu (latitude, longitude) {
      // var center = new TMap.LatLng(39.98210863924864, 116.31310899739151)
      TMap.init().then((TMap) => {
        var center = new TMap.LatLng(latitude, longitude)

        // 初始化地图
        var map = new TMap.Map('container', {
          zoom: 17, // 设置地图缩放
          center: new TMap.LatLng(latitude, longitude), // 设置地图中心点坐标，
          viewMode: '2D',
          pitch: 0, // 俯仰度
          rotation: 0 // 旋转角度
        })

        // MultiMarker文档地址：https://lbs.qq.com/webApi/javascriptGL/glDoc/glDocMarker
        var marker = new TMap.MultiMarker({
          map: map,
          styles: {
            // 点标记样式
            marker: new TMap.MarkerStyle({
              width: 20, // 样式宽
              height: 30, // 样式高
              anchor: { x: 10, y: 30 } // 描点位置
            })
          },
          geometries: [
            // 点标记数据数组
            {
              // 标记位置(纬度，经度，高度)
              position: center,
              id: 'marker'
            }
          ]
        })
        console.log(marker)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
