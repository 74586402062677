<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户名称">
                <a-input placeholder="请输入用户名称" v-model="queryParam.userName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号码">
                <a-input placeholder="请输入手机号码" v-model="queryParam.telephone" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="签到时间">
                <a-range-picker
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  v-model="searcDate"
                  @change="changeTime"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="网格卡点">
                  <a-select
                    show-search
                    placeholder="请选择"
                    allow-clear
                    :options="pointList"
                    option-filter-prop="children"
                    @change="handleChange"
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="gotoInfo(record.id)">查看</a>
        </span>
      </s-table>
      <see-signin ref="seesignin"></see-signin>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { gridCondition, gridSignInList } from '@/api/gridevent'
import SeeSignin from '@/views/grid/components/SeeSignin'
const columns = [
  {
    title: '编号',
    dataIndex: 'id',
    width: '100px'
  },
  {
    title: '用户名称',
    dataIndex: 'realName'
  },
  {
    title: '手机号码',
    dataIndex: 'telephone'
  },
  {
    title: '网格卡点',
    dataIndex: 'gridName'
  },
  {
    title: '签到时间',
    dataIndex: 'signInTimeDate'
  },
  {
    title: '签到地点',
    dataIndex: 'signInAddress'
  },
  {
    title: '操作',
    width: '60px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'SignInList',
  components: {
    STable,
    Ellipsis,
    STree,
    SeeSignin
  },
  data () {
    this.columns = columns
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        userName: '',
        telephone: '',
        startTime: '',
        endTime: '',
        gridId: undefined
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return gridSignInList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      searcDate: [],
      pointList: []
    }
  },
  filters: {
  },
  created () {
    this.gridConditionApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'CommunityActivitiesList') {
        this.queryParam = {
          userName: '',
          telephone: '',
          startTime: '',
          endTime: '',
          gridId: 0
        }
        this.searcDate = []
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        userName: '',
        telephone: '',
        startTime: '',
        endTime: '',
        gridId: 0
      }
      this.searcDate = []
    },
    changeTime (e) {
      this.queryParam.startTime = e[0] ? e[0] : ''
      this.queryParam.endTime = e[1] ? e[1] : ''
      this.searcDate = e
      console.log('changeTime', e)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    gotoInfo (keyId) {
      this.$refs.seesignin.dataInfoApi(keyId)
    },
    gridConditionApi () {
      const _this = this
      gridCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.pointList = res.result.pointList
          console.log(res)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleChange (e) {
      console.log(e)
      if (e === undefined || e === 0) {
        this.queryParam.gridId = 0
      } else {
        this.queryParam.gridId = e
      }
    }
  }
}
</script>
